<template>
  <AccountSharedLayout
    class="AccountCheckoutView"
    :show-legal="true"
    :side-on-top="true">
    <!-- <h1 slot="title">AccountCheckoutView</h1> -->
    <div slot="main">
      <form @submit.prevent="subscribe()">
        <div class="legend">
          <span
            v-t="'account.AccountCheckoutView.selectedPlan'"
            class="label" />
        </div>

        <div class="plans">
          <PlanItem
            v-for="p of plans"
            :key="p.id"
            class="Plan"
            :selected="p.id === selectedPlanId"
            v-bind="p"
            @click.native="selectPlan(p)" />
        </div>
        <StripeCard
          v-if="selectedPlan && !whitelistedVoucher"
          ref="card"
          :plan="selectedPlan.id"
          :product="selectedPlan.name"
          :amount="selectedPlan.price"
          :currency="selectedPlan.currency"
          @express-checkout="expressCheckout" />
        <p
          v-if="backendError"
          role="error"
          class="alert-danger">
          {{ backendError }}
        </p>

        <SubmitButton
          class="submitButton"
          :label="$t('account.AccountCheckoutView.subscribe')"
          :disabled="$v.$invalid"
          :working="isProcessing" />

        <div class="salesTerms">
          <span v-t="'account.AccountCheckoutView.sales_terms_pre'" />&nbsp;
          <span
            v-t="'account.AccountCheckoutView.sales_terms_linklabel'"
            class="salesTermsLink --global-clickable"
            @click="openTermsTest($t('account.legalDocuments.salesTerms.url'))" />
        </div>
      </form>
    </div>

    <div
      slot="side"
      class="side">
      <OrderSummarySection
        v-if="selectedPlan"
        class="orderSummarySection"
        :plan-id="selectedPlanId"
        :trial="trial"
        :plan="selectedPlan"
        :currency-symbol="currencySymbol" />

      <div
        v-if="selectedPlan && trial"
        class="trial-info">
        {{ $t('account.AccountCheckoutView.trialInfo', { trialPeriod: selectedPlan.trialPeriod }) }}
      </div>

      <div
        v-t="'account.AccountCheckoutView.cancelInfo'"
        class="cancel-info" />

      <CouponSection
        :currency-symbol="currencySymbol"
        :plan="selectedPlan"
        class="couponSection" />

      <div class="spacer" />

      <QuoteItem class="Testimonial" />

      <div class="spacer" />

      <div class="verified">
        <a
          href="https://www.stripe.com"
          target="_blank"><img
            width="150"
            src="./powered-by-stripe.png"></a>
      </div>
    </div>
  </AccountSharedLayout>
</template>

<script>
import AccountSharedLayout from '../../components/AccountSharedLayout';
import SubmitButton from '../../components/SubmitButton';
import SimpleMarkdownViewer from '../../components/SimpleMarkdownViewer';
import StripeCard from '../../components/StripeCard';

import PlanItem from './PlanItem';
import QuoteItem from './QuoteItem';
import CouponSection from './CouponSection';
import OrderSummarySection from './OrderSummarySection';
import couponStore from './coupon-data-helper';
import cbPlans from './plans';

const _3dsecure = true;

export default {
    components: {
        AccountSharedLayout,
        SubmitButton,
        PlanItem,
        QuoteItem,
        CouponSection,
        OrderSummarySection,
        StripeCard
    },
    data() {
        return {
            currency: 'nok',
            plan: {},
            trial: true,

            error: '',
            backendError: '',

            isProcessing: false,

            selectedPlan: null,

            couponState: couponStore.state
        };
    },

    computed: {
        selectedPlanId() {
            if (this.selectedPlan) {
                return this.selectedPlan.id;
            }
            return null;
        },

        plans() {
            return cbPlans.filter(p => {
                if (this.couponPlanType) {
                  return p.planType === this.couponPlanType;
                }
              return p.currency === this.currency;
            });
        },

        currencySymbol() {
            return { usd: '$', nok: 'kr' }[this.currency];
        },

        couponDoesNotRequireCC() {
            const couponData = this.couponState.data;
            return couponData && couponData.meta_data && couponData.meta_data.require_cc === false;
        },

        couponPlanType() {
            const d = this.couponState.data;
            if (d && d.meta_data && d.meta_data.plan_type) {
              return d.meta_data.plan_type;
            }
            return null;
        },

        whitelistedVoucher() {
            const couponData = this.couponState.data;
            if (!couponData.id) {
                return false;
            }
            if (this.couponDoesNotRequireCC) {
                return true;
            }
            return (
                couponData.duration_type === 'forever' &&
                couponData.discount_type === 'percentage' &&
                couponData.discount_percentage === 100
            );
        }
    },

    watch: {
        'plans.length'() {
            this.selectPlan(this.plans[0]);
        }
    },
    created() {},

    mounted() {
        this.setupCurrency();
        let plans = this.plans.filter(p => p.planType === this.$route.params.plan);
        if (plans.length > 0) {
            this.selectPlan(plans[0]);
        } else {
            this.selectPlan(this.plans[0]);
        }
    },

    methods: {
        expressCheckout() {
            console.log('expressCheckout()');
            this.isProcessing = true;
            this.backendError = '';
            return this.subscribeCustomer()
                .catch(err => {
                    this.backendError = err.data.error;
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
        setupCurrency() {
            this.currency = this.$store.state.moduleAuth.profile.currency;
        },

        addPaymentSource() {
            if (this.$refs.card) {
                return this.$refs.card.addPaymentSource();
            }
            return Promise.resolve();
        },

        createPaymentMethod() {
            if (this.$refs.card) {
                return this.$refs.card.createPaymentMethod();
            }
            return Promise.resolve();
        },

        captureCard() {
            console.log('3dsecure', _3dsecure);
            if (_3dsecure) {
                return this.createPaymentMethod();
            } else {
                return this.addPaymentSource();
            }
        },

        async subscribe() {
            console.log('subscribe()');
            if (this.$v.$invalid || this.isProcessing) {
                return;
            }

            this.isProcessing = true;
            this.backendError = '';

            return this.captureCard()
                .then(this.subscribeCustomer)
                .catch(error => {
                    if (this.$refs.card && this.$refs.card.$v.$invalid) {
                        // pass on validation errors
                    } else if (error.data && error.data.error) {
                        this.backendError = error.data.error;
                    } else if (error.response && error.response.data && error.response.data.error) {
                        this.backendError = error.response.data.error;
                    } else {
                        this.backendError = error.message;
                        // this.backendError = 'an error occured, please try again later'
                    }
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
        async subscribeCustomer(paymentIntentId) {
            let subscriptionData = this.setupSubscriptionData();
            subscriptionData.payment_intent_id = paymentIntentId;
            await this.$store.dispatch('moduleAuth/subscribe', subscriptionData);
            window.dataLayer.push({
                event: 'subscribe_customer',
                plan: this.selectedPlan.id,
                price: this.selectedPlan.price
            });
            this.$router.push({ name: 'DashboardHomeView' });
        },
        selectPlan(plan) {
            this.selectedPlan = plan;
            if (this.$route.params.plan === (plan && plan.planType)) {
                return;
            }
            this.$router.push({ name: 'AccountCheckoutView', params: { plan: plan.planType } });
        },
        setupSubscriptionData() {
            let data = {
                subscription: { plan_id: this.selectedPlanId, trial: this.trial }
            };

            const couponData = this.couponState.data;
            if (couponData && couponData.id) {
                data.coupon = couponData.id;
            }
            return data;
        },
        openTermsTest(url) {
            this.$modal.open({
                target: 'app-modal',
                wrap: true,
                component: SimpleMarkdownViewer,
                props: {
                    url: url,
                    content: 'some **bla bla** content',
                    markdown: true
                }
            });
        }
    },
    validations() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
$grayTextColor-dark: #333;
$grayTextColor-medium: #777;
$grayLineColor: #ddd;

.plans {
    display: flex;
    margin-bottom: 2em;
    margin-top: 1em;
}

.spacer {
    flex: 1;
}

.side {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    min-height: 100%;
}

.submitButton {
    width: 100%;
    margin-top: 2em;
}

.orderSummarySection {
    margin-bottom: 2em;
}

.couponSection {
    margin-top: 2em;
}

.trial-info {
    background-color: #173166;
    color: white;
    text-align: center;
    padding: 0.5em 1em;
    margin-bottom: 1em;
}
.cancel-info {
    color: $grayTextColor-medium;
    font-size: 90%;
}

fieldset {
    border: none;
    padding: 0px;
    margin: 0px;
}

.FormGroup,
.form-group {
    margin-top: 1em;
}

.salesTerms {
    margin-top: 1em;
}
.salesTermsLink {
    color: $greenButtonBackground;
}

// ---------------------------
// responsive
// ---------------------------

.AccountCheckoutView.useMobileLayout {
    .Testimonial,
    .verified {
        display: none;
    }
}

.alert-danger {
    background-color: #edc8c5;
    color: #000;
    padding: 1em;
    margin-bottom: 1em;
}
</style>
