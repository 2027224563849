<template>
  <div class="OrderSummarySection">
    <div
      v-t="'account.AccountCheckoutView.orderSummary'"
      class="header legend" />

    <div class="subscription">
      <div>
        <span v-t="`account.AccountCheckoutView.planNames.${plan.planType}`" />&nbsp;<span
          v-t="'account.AccountCheckoutView.subscription'" />
      </div>
      <div>
        <span>{{ currencySymbol }}</span> <span>{{ plan.price }}</span>
      </div>
    </div>

    <div
      v-if="xxx.discountForFirstPeriod"
      class="discount">
      <div>
        <span v-t="'account.AccountCheckoutView.payment.discount'" />
      </div>
      <div>
        - <span>{{ currencySymbol }}</span> <span>{{ xxx.discountForFirstPeriod }}</span>
      </div>
    </div>

    <div class="hline" />

    <div class="total">
      <div
        v-if="!trial"
        v-t="'account.AccountCheckoutView.total'" />
      <div v-if="trial">
        {{ $t('account.AccountCheckoutView.totalAfterTrial', { trialPeriod: plan.trialPeriod }) }}
      </div>
      <div>
        <span>{{ currencySymbol }}</span> <span>{{ xxx.totalForFirstPeriod }}</span>
      </div>
    </div>

    <div class="hline" />
  </div>
</template>

<script>
import couponStore from './coupon-data-helper';

export default {
    props: {
        planId: {
            type: String,
            required: true
        },
        currencySymbol: {
            type: String,
            default: '$'
        },
        discountForFirstPeriod: {
            type: Number,
            default: 10
        },
        trial: {
            default: true
        },
        plan: {
            default: () => ({ price: 18 })
        }
    },
    data() {
        return {
            couponState: couponStore.state
        };
    },
    computed: {
        xxx() {
            const couponData = this.couponState.data;
            let durationType = couponData.duration_type;
            let discountType = couponData.discount_type;
            let planPrice = parseInt(this.plan.price, 10);

            let discountForFirstPeriod = 0;
            let totalForFirstPeriod = planPrice;

            if (durationType === 'one_time') {
                if (discountType === 'percentage') {
                    discountForFirstPeriod = (planPrice / 100) * couponData.discount_percentage;
                } else if (discountType === 'fixed_amount') {
                    discountForFirstPeriod = couponData.discount_amount / 100;
                }
            } else if (durationType === 'forever') {
                if (discountType === 'percentage') {
                    discountForFirstPeriod = (planPrice / 100) * couponData.discount_percentage;
                } else if (discountType === 'fixed_amount') {
                    discountForFirstPeriod = couponData.discount_amount / 100;
                }
            } else if (durationType === 'limited_period') {
                let monthCountInPlan = { month: 1, biannual: 6, annual: 12 }[this.plan.id];
                let discountDuration = couponData.duration_month;

                if (monthCountInPlan === discountDuration) {
                    // NOTE: Same as 'one_time'
                    if (discountType === 'percentage') {
                        discountForFirstPeriod = (planPrice / 100) * couponData.discount_percentage;
                    } else if (discountType === 'fixed_amount') {
                        discountForFirstPeriod = couponData.discount_amount / 100;
                    }
                } else if (monthCountInPlan > discountDuration) {
                    let origialPriceForDiscountDuration = (planPrice / monthCountInPlan) * discountDuration;
                    let amount = 0;
                    if (discountType === 'percentage') {
                        amount = (origialPriceForDiscountDuration / 100) * couponData.discount_percentage;
                    } else if (discountType === 'fixed_amount') {
                        // NOTE: this assumes that discount_amount is a sensible value
                        amount = (couponData.discount_amount / 100) * discountDuration;
                    }
                    discountForFirstPeriod = amount;
                } else {
                    // The discountDuration is larger than the monthCountInPlan.
                    if (discountType === 'percentage') {
                        discountForFirstPeriod = (planPrice / 100) * couponData.discount_percentage;
                    } else if (discountType === 'fixed_amount') {
                        discountForFirstPeriod = (couponData.discount_amount / 100) * monthCountInPlan;
                    }
                }
            } else {
                discountForFirstPeriod = 0;
                totalForFirstPeriod = planPrice;
            }

            totalForFirstPeriod = planPrice - discountForFirstPeriod;

            return {
                discountForFirstPeriod,
                totalForFirstPeriod
            };
        }
    }
};
</script>

<style lang="scss" scoped>
$grayTextColor-dark: #333;
$grayTextColor-medium: #777;
$grayLineColor: gray;

.order-summary {
    margin-bottom: 2em;
    color: $grayTextColor-dark;
}
.subscription,
.trial,
.total,
.discount {
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
}

.total-trial {
    margin-top: 1em;
    color: $greenButtonBackground;
}
.hline {
    height: 1px;
    background-color: $grayLineColor;
}
</style>
