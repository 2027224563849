<template>
  <div class="QuoteItem">
    <div
      v-t="'account.AccountCheckoutView.testimonial.quote'"
      class="quote" />
    <div class="faceAndName">
      <div
        class="face"
        :style="{ backgroundImage: `url(${image})` }" />
      <div
        v-t="'account.AccountCheckoutView.testimonial.name'"
        class="name" />
    </div>
  </div>
</template>

<script>
import image from './checkout-testimonial.png';

export default {
    computed: {
        image() {
            return image;
        }
    }
};
</script>

<style lang="scss" scoped>
$grayTextColor-dark: #333;
$grayTextColor-medium: #777;

.QuoteItem {
}
.quote {
    position: relative;
    background-color: whitesmoke;
    padding: 1em;
    margin-bottom: 1em;
    color: $grayTextColor-dark;

    &::after {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid whitesmoke;
        left: calc(2em - 10px);
        bottom: -10px;
    }
}
.faceAndName {
    display: flex;
    align-items: center;
}
.face {
    width: 4em;
    height: 4em;
    background-size: cover;
    border-radius: 50%;
    flex-shrink: 0;

    background-color: gray;
}
.name {
    margin-left: 1em;
    color: $grayTextColor-medium;
}
</style>
