<template>
  <div class="CouponSection">
    <div
      v-if="collapsed"
      v-t="'account.AccountCheckoutView.addCoupon'"
      xv-if="!(couponInputFieldVisible || validCoupon)"
      class="addCouponButton --global-clickable"
      @click="showCouponInputField()" />

    <template v-else>
      <div
        v-if="!validCoupon"
        class="group-coupon">
        <div
          class="coupon-input"
          xv-if="couponInputFieldVisible">
          <input
            v-model="couponId"
            class="inputArea"
            type="text"
            :placeholder="$t('coupon')"
            @keyup.enter="validateCoupon()">
          <button
            v-t="'addButton'"
            class="addButton"
            @click="validateCoupon()" />
        </div>

        <div
          v-if="couponState.error"
          v-t="'error'"
          class="coupon-error" />
        <div
          v-if="couponState.pending"
          v-t="'checking'"
          class="coupon-checking" />
      </div>

      <div
        v-if="validCoupon"
        class="coupon">
        <div class="coupon-name">
          {{ couponState.data.name }}
          <svgicon
            class="checkmark"
            icon="checkmark" />
        </div>
        <div
          class="coupon-text"
          v-html="couponText" />
      </div>

      <div
        v-if="validCoupon"
        v-t="'removeCoupon'"
        class="coupon-remove"
        @click="removeCoupon()" />
    </template>
  </div>
</template>

<translations>
  addButton: 'Add'
  addButton_no: 'Legg til'
  removeCoupon: 'Remove coupon'
  removeCoupon_no: 'Fjern kupong'
  coupon: 'Coupon'
  coupon_no: 'Kupong'
  checking: 'Checking...'
  checking_no: 'Sjekker...'
  error: 'Your coupon does not exist'
  error_no: 'Kupongen eksisterer ikke'

  onetimePercentage: 'Your coupon gives you {percentage}% discount for the first subscription period'
  onetimePercentage_no: 'Kupongen gir deg {percentage}% rabatt for den første perioden'

  onetimeFixed: 'Your coupon gives you a <span>{currency}</span> {amount} discount for the first subscription period'
  onetimeFixed_no: 'Kupongen gir deg <span>{currency}</span> {amount} rabatt for den første perioden'

  foreverPercentage: 'Your coupon gives you {percentage}% discount per period'
  foreverPercentage_no: 'Kupongen gir deg {percentage}% rabatt per periode'

  foreverFixed: 'Your coupon gives you a <span>{currency}</span> {amount} discount per period'
  foreverFixed_no: 'Kupongen gir deg <span>{currency}</span> {amount} rabatt per periode'

  limitedLongerPercentage: 'Your coupon gives you {percentage}% discount for {duration} months'
  limitedLongerPercentage_no: 'Kupongen gir deg {percentage}% rabatt for {duration} måneder'

  limitedLongerFixed: 'Your coupon gives you a <span>{currency}</span> {amount} discount per month for {duration} months'
  limitedLongerFixed_no: 'Kupongen gir deg <span>{currency}</span> {amount} rabatt per måned for {duration} måneder'
</translations>

<script>
import couponStore from './coupon-data-helper';

export default {
    props: {
        currencySymbol: {
            type: String,
            default: '$'
        },
        plan: {
            type: Function,
            default: () => ({ price: 18 })
        }
    },
    data() {
        return {
            collapsed: true,
            couponInputFieldVisible: false,
            couponId: '',
            couponState: couponStore.state
        };
    },
    computed: {
        validCoupon() {
            return this.couponState.data.id;
        },
        couponText() {
            let text = '';
            if (!this.validCoupon) {
                return text;
            }

            let durationType = this.couponState.data.duration_type;
            let discountType = this.couponState.data.discount_type;
            let planPrice = parseInt(this.plan.price, 10);
            let planType = this.plan.planType;

            const couponData = this.couponState.data;

            if (durationType === 'one_time') {
                if (discountType === 'percentage') {
                    text = this.$t('onetimePercentage', { percentage: couponData.discount_percentage });
                } else if (discountType === 'fixed_amount') {
                    text = this.$t('onetimeFixed', {
                        amount: (couponData.discount_amount / 100).toFixed(2),
                        currency: this.currencySymbol
                    });
                }
            } else if (durationType === 'forever') {
                if (discountType === 'percentage') {
                    text = this.$t('foreverPercentage', { percentage: couponData.discount_percentage });
                } else if (discountType === 'fixed_amount') {
                    text = this.$t('foreverFixed', {
                        amount: (couponData.discount_amount / 100).toFixed(2),
                        currency: this.currencySymbol
                    });
                }
            } else if (durationType === 'limited_period') {
                let monthCountInPlan = { month: 1, biannual: 6, annual: 12 }[planType];
                let discountDuration = couponData.duration_month;

                if (monthCountInPlan === discountDuration) {
                    // NOTE: Same as 'one_time'
                    if (discountType === 'percentage') {
                        text = this.$t('onetimePercentage', { percentage: couponData.discount_percentage });
                    } else if (discountType === 'fixed_amount') {
                        text = this.$t('onetimeFixed', {
                            amount: (couponData.discount_amount / 100).toFixed(2),
                            currency: this.currencySymbol
                        });
                    }
                } else if (monthCountInPlan > discountDuration) {
                    let origialPriceForDiscountDuration = (planPrice / monthCountInPlan) * discountDuration;
                    let amount = 0;
                    if (discountType === 'percentage') {
                        amount = (origialPriceForDiscountDuration / 100) * couponData.discount_percentage;
                    } else if (discountType === 'fixed_amount') {
                        // NOTE: this assumes that discount_amount is a sensible value
                        amount = (couponData.discount_amount / 100) * discountDuration;
                    }
                    text = this.$t('onetimeFixed', { amount: amount.toFixed(2), currency: this.currencySymbol });
                } else {
                    // The discountDuration is larger than the monthCountInPlan.
                    if (discountType === 'percentage') {
                        text = this.$t('limitedLongerPercentage', {
                            duration: discountDuration,
                            percentage: couponData.discount_percentage
                        });
                    } else if (discountType === 'fixed_amount') {
                        text = this.$t('limitedLongerFixed', {
                            amount: (couponData.discount_amount / 100).toFixed(2),
                            duration: discountDuration,
                            currency: '$'
                        });
                    }
                }
            } else {
                text = 'why here?';
            }

            return text;
        }
    },
    watch: {
        couponId() {
            couponStore.removeCoupon();
        }
    },
    methods: {
        removeCoupon() {
            this.couponInputFieldVisible = true;
            this.couponId = '';
            couponStore.removeCoupon();
        },
        validateCoupon() {
            // this.couponInputFieldVisible = false
            couponStore.loadCoupon(this.couponId);
        },
        showCouponInputField() {
            this.collapsed = false;
            // this.couponInputFieldVisible = true
        }
    }
};
</script>

<style lang="scss" scoped>
$grayTextColor-dark: #333;
$grayTextColor-medium: #777;
$redWarningBackground: red;

.CouponSection {
    margin-top: 2em;
}

.addCouponButton {
    color: $greenButtonBackground;
    margin-bottom: 1em;
}

.group-coupon {
    position: relative;
}
.coupon-error {
    margin-top: 0.5em;
    background-color: $redWarningBackground;
    padding: 0.5em 1em;
    color: white;
}

.coupon-checking {
    color: $grayTextColor-medium;
    margin-top: 0.5em;
}

.coupon-name {
    color: $greenButtonBackground;
    .checkmark {
        float: right;
    }
}
.coupon-text {
    margin-top: 0.5em;
}
.coupon {
    background-color: #ecf8f1;
    padding: 1em 1em;
    color: $grayTextColor-dark;
    border: 1px dashed $greenButtonBackground;

    .checkmark {
        $size: 1em;
        width: $size;
        height: $size;
        fill: $greenButtonBackground;
    }
}
.coupon-input {
    width: 100%;
    display: flex;
}
.coupon-remove {
    color: $greenButtonBackground;
    cursor: pointer;
    text-align: right;
    padding: 0.5em 0;
}
.inputArea {
    // margin-bottom: 1em;
    flex: 1;
    outline: none;
    padding: 0.5em 1em;
    font: inherit;
    width: 100%;
    border: 1px solid #ddd;
    color: #555;

    &:focus {
        border-color: blue;
    }
}
.addButton {
    margin-left: 0.5em;

    font: inherit;
    background: $greenButtonBackground;
    border: 1px solid $greenButtonBackground;
    color: white;
    padding: 0.3em 1em;
    font-size: 90%;
    border-radius: 2px;
    outline: none;

    cursor: pointer;
}
</style>
