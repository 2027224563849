import { getCoupon } from '@/account-utils';

const store = {
    state: {
        counter: 1,
        data: {},
        pending: false,
        error: false
    },
    async loadCoupon(couponId) {
        this.state.pending = true;
        try {
            this.state.data = await getCoupon(couponId);
        } catch (err) {
            this.state.error = true;
            this.state.data = {};
            return;
        } finally {
            this.state.pending = false;
        }
        this.state.error = false;
    },
    removeCoupon() {
        this.state.data = {};
        this.state.error = false;
    }
};

// setInterval(() => {
//   store.state.counter += 1
// }, 1000)

export default store;
